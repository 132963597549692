import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import Hero from "../components/hero"

const ContactPage = () => {
  return (
    <Layout title="SB Relocations UK - Nationwide Relocation Company" >
      <Hero />
      <section className="lg:max-w-4xl mx-auto mt-8 text-lg sm:text-xl md:text-2xl font-thin wrapper rounded-md shadow bg-sb p-6">
        SB Relocations locates, manages and maintains a range of accommodations,
        from single occupancy properties to multiple occupancy properties.
      </section>
      <main className="mt-8 mb-16">
        <section id="accomodations" className="mt-8 md:mt-12 lg:mt-16">
          <h2 className="h1">Our Accomodations</h2>
          <StaticImage
            className="mb-4"
            src="../images/shutterstock_1210029238.jpeg"
            aspectRatio={16 / 6}
            width={1000}
            quality={80}
            alt="Bedroom"
          />
          <div>
            <p>
              Our Accommodation Units are elegantly furnished with everything a
              traveller needs whether it be 3 nights, days, or months, offering
            </p>
            <ul className="list-disc list-inside">
              <li>Long or short term lets</li>
              <li>Fully equipped kitchen</li>
              <li>Complimentary Netflix</li>
              <li>Complimentary Wi-Fi</li>
              <li>Comfortable furniture</li>
              <li>Cozy beds &amp; sofa</li>
              <li>Professionally cleaned</li>
              <li>Nespresso machine</li>
            </ul>
            <p className="pt-2 text-center">
              <a
                href="//booking.sbrelocations.uk"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-primary"
              >
                BOOK NOW
              </a>
            </p>
          </div>
        </section>

        <section id="agents" className="mt-8 md:mt-12 lg:mt-16">
          <h2 className="h1">Agents: How can we work together?</h2>
          <StaticImage
            className="mb-4"
            src="../images/shutterstock_342259889.jpeg"
            aspectRatio={16 / 6}
            width={1000}
            quality={80}
            alt="Bathroom"
          />
          <div>
            <p>
              At SB Relocations we enjoy working in partnership with Letting
              Agents for long term mutual benefit.
            </p>
            <ul className="list-disc list-inside">
              <li>We source and manage tenants</li>
              <li>
                You receive the management fee from the landlord (if propriety
                is managed by you)
              </li>
              <li>You can still manage the property if required</li>
              <li>You receive all usual Agents fees</li>
              <li>Less of your time and resources spent per property</li>
              <li>We have professional house-keeping service </li>
              <li>We are regulated by the Property Ombudsman Scheme</li>
              <li>
                Comprehensive Insurance in place - extra layer of protection of
                the unit
              </li>
            </ul>
          </div>
        </section>

        <section id="corporate" className="mt-8 md:mt-12 lg:mt-16">
          <h2 className="h1">Corporate Clients</h2>
          <StaticImage
            className="mb-4"
            src="../images/shutterstock_492287980.jpeg"
            aspectRatio={16 / 6}
            width={1000}
            quality={80}
            alt="Living area"
          />
          <div>
            <p>
              Our corporate clients understand that a company’s most valuable
              resource is its employees. An employee’s decision to move with a
              company is a major event which may have a massive impact on the
              company, the individual and their family. We appreciate your need
              to be sure that the corporate relocation company you choose will
              deliver a service which, apart from being value for money, also
              provides total peace of mind for both the company and the
              employee. We will support your employees every step of the way and
              handle all aspects of their relocation.
            </p>
            <p>
              We also understand that you will require clear costs at the
              outset, and our assurance that the services agreed are met fully
              and to your complete satisfaction. We will provide you with
              regular progress reports, keeping you in the loop that everything
              is being done to ensure a smooth transition and fast assimilation
              for your valuable key employees and their family. We aim to take
              the stress and pressure out of a company’s corporate relocation,
              allowing your employees the time to totally focus on their role.
              We are able to offer flexible relocation services which can be
              modified to you and your employee requirements.
            </p>
            <p>Our accommodations offer:</p>
            <ul className="list-disc list-inside">
              <li>All our proprieties are fully furnished</li>
              <li>Complimentary Tea / Coffe</li>
              <li>Complimentary superfast internet</li>
              <li>Complimentary Netflix</li>
              <li>Parking On-site (may have some charges)</li>
              <li>Complimentary Toiletries and hotel linen</li>
            </ul>
            <p>What we offer:</p>
            <ul className="list-disc list-inside">
              <li>We are a viable economic option to hotels</li>
              <li>Professional cleaning teams service our proprieties</li>
              <li>We are your direct point of contact</li>
              <li>No hidden costs</li>
              <li>
                Public indemnity and public liability insurance policies in
                place
              </li>
            </ul>
            <p className="pt-2 text-center">
              <a
                href="//booking.sbrelocations.uk"
                target="_blank"
                rel="noreferrer"
                className="btn btn-lg btn-primary"
              >
                BOOK NOW
              </a>
            </p>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default ContactPage
