import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
// import PropTypes from "prop-types"
// import { Link } from "gatsby"

// import { Fragment } from "react"
// import { Popover, Transition } from "@headlessui/react"
// import { MenuIcon, XIcon } from "@heroicons/react/outline"
import logoPropertyOmbudsman from "../images/the-property-ombudsman.svg"

const Hero = props => (
  <section className={`relative bg-color overflow-hidden ${props.className}`}>
    <div className="relative bg-color overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-color sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute fill-bg-color right-0 inset-y-0 h-full w-48 transform translate-x-1/2"
            //fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="pt-10 sm:pt-12 md:pt-16 lg:pt-20 xl:pt-28 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Nationwide</span>{" "}
                <span className="block text-yellow-600 xl:inline">
                  Relocation Company
                </span>
              </h1>
              <p className="mt-3 text-base text-current sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                SB Relocations is specialise in providing short and long term
                accommodations for corporates and professional clients.
              </p>
              <div className="mt-5 sm:mt-8 flex flex-col xs:flex-row gap-4 sm:justify-center lg:justify-start">
                {/* <div className="mt-3 px-3 sm:mt-0 sm:px-0"> */}
                  <img
                    src={logoPropertyOmbudsman}
                    alt="The Property Ombudsman Member"
                    className="block h-16"
                  />
                {/* </div> */}
                {/* <div className="mt-3 px-3 sm:mt-0 sm:px-0 sm:pl-3 flex"> */}
                  <StaticImage
                    src="../images/tra-2022.png"
                    height={64}
                    alt="Booking.com - Traveller Review Awards 2022 - Score: 9.6"
                    className="mx-auto xs:mx-0 h-16"
                  />
                {/* </div> */}
                {/* <div className="rounded-md shadow">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                  Get started
                </a>
              </div> */}

                {/* <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="#"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                >
                  Live demo
                </a>
              </div> */}
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <StaticImage
          src="../images/shutterstock_1753153154.jpeg"
          width={800}
          alt="Interior of a kitchen"
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
        />
      </div>
    </div>
  </section>
)

export default Hero
